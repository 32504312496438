// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-index-index-js": () => import("./../../../src/components/Index/index.js" /* webpackChunkName: "component---src-components-index-index-js" */),
  "component---src-pages-flight-against-time-js": () => import("./../../../src/pages/flight-against-time.js" /* webpackChunkName: "component---src-pages-flight-against-time-js" */),
  "component---src-pages-night-under-the-stars-js": () => import("./../../../src/pages/night-under-the-stars.js" /* webpackChunkName: "component---src-pages-night-under-the-stars-js" */),
  "component---src-pages-projects-adminca-js": () => import("./../../../src/pages/projects/adminca.js" /* webpackChunkName: "component---src-pages-projects-adminca-js" */),
  "component---src-pages-projects-annotation-manager-js": () => import("./../../../src/pages/projects/annotation-manager.js" /* webpackChunkName: "component---src-pages-projects-annotation-manager-js" */),
  "component---src-pages-projects-cogniac-js": () => import("./../../../src/pages/projects/cogniac.js" /* webpackChunkName: "component---src-pages-projects-cogniac-js" */),
  "component---src-pages-projects-dino-fit-js": () => import("./../../../src/pages/projects/dino-fit.js" /* webpackChunkName: "component---src-pages-projects-dino-fit-js" */),
  "component---src-pages-projects-goggles-js": () => import("./../../../src/pages/projects/goggles.js" /* webpackChunkName: "component---src-pages-projects-goggles-js" */),
  "component---src-pages-projects-smart-bricks-js": () => import("./../../../src/pages/projects/smart-bricks.js" /* webpackChunkName: "component---src-pages-projects-smart-bricks-js" */)
}

